import 'normalize.css'
import './index.css'

import React from 'react'
import styled from 'styled-components'

import Member, { MemberModel } from './components/Member'
import Product, { ProductModel } from './components/Product'
import images from './images'

const products: ProductModel[] = [
  {
    name: 'カクテル.jp',
    slogan: 'カクテルが好きなみんなでつくるカクテルデータベース・ツール',
    logo: images.kakuteruJp,
    web: 'https://kakuteru.jp',
  },
  // {
  //   name: 'J Foodie',
  //   slogan: 'Find awarded Japanese restaurants',
  //   logo: images.jfoodie,
  //   ios: 'https://itunes.apple.com/us/app/j-foodie/id1361909254',
  // },
]
const members: MemberModel[] = [
  {
    name: 'duan',
    role: 'developer, videographer',
    intro:
      'coding with TypeScript, Swift, Rust and Haskell\n' +
      'filming with Sony A7M3, BMPCC and GoPro',
    accounts: [
      {
        type: 'stackoverflow',
        url: 'http://stackoverflow.com/users/4149826/duan',
      },
      { type: 'github', url: 'https://github.com/wddwycc' },
      { type: 'twitter', url: 'https://twitter.com/wddwycc' },
      { type: 'unsplash', url: 'https://unsplash.com/@wddwycc' },
    ],
  },
  {
    name: 'lan',
    role: 'developer, game designer',
    intro:
      'sharing life in video on bilibili\n' +
      'making games with Unity3D\n' +
      'interested in interaction between human and things. \n',
    accounts: [
      { type: 'bilibili', url: 'https://space.bilibili.com/9017360' },
      { type: 'github', url: 'https://github.com/ElsaXing' },
    ],
  },
]

const Container = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`
const Body = styled.div``
const Head = styled.div`
  margin: 24px 0;
`
const Logo = styled.div`
  width: 196px;
  height: 54px;
  background-image: url(${images.monkStudio});
  background-size: cover;
`
const Slogan = styled.div`
  margin-top: 6px;
  font-size: 20px;
`
const SubTitle = styled.h2`
  margin-top: 48px;

  font-size: 16px;
  opacity: 0.4;
`

const App = () => {
  return (
    <Container>
      <Body>
        <Head>
          <Logo />
          <Slogan>Software studio based in Japan</Slogan>
        </Head>
        <section>
          <SubTitle>Products</SubTitle>
          {products.map((a) => (
            <Product key={a.name} {...a} />
          ))}
        </section>
        <section>
          <SubTitle>Crew</SubTitle>
          {members.map((a) => (
            <Member key={a.name} {...a} />
          ))}
        </section>
      </Body>
    </Container>
  )
}

export default App
