export default {
  bilibili: require('./social/bilibili.svg'),
  github: require('./social/github.svg'),
  stackoverflow: require('./social/stackoverflow.svg'),
  twitter: require('./social/twitter.svg'),
  unsplash: require('./social/unsplash.svg'),

  appStore: require('./appStore.svg'),
  googlePlay: require('./googlePlay.svg'),
  website: require('./website.svg'),

  jfoodie: require('./jfoodie.png'),
  kakuteruJp: require('./kakuteru-jp.png'),

  monkStudio: require('./monkStudio.svg'),
}
