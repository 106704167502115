import React, { FC } from 'react'
import styled from 'styled-components'

import images from '../images'

const Container = styled.div`
  margin: 16px 0;
`
const Name = styled.h3`
  margin-bottom: 0;
  font-size: 1.5em;
`
const Role = styled.div`
  opacity: 0.6;
`
const Accounts = styled.div`
  margin-top: 4px;
`
const Account = styled.a<{ type: AccountType }>`
  width: 16px;
  height: 16px;

  display: inline-block;
  margin-right: 8px;

  fill: white;
  opacity: 0.4;
  background-image: url(${(p) => accountLogo(p.type)});
  background-size: cover;

  :hover {
    opacity: 0.6;
  }
`
const Intro = styled.p`
  margin-top: 12px;
  font-size: 14px;
  white-space: pre-line;
  line-height: 1.5em;
`

const accountLogo = (src: AccountType) => {
  switch (src) {
    case 'github':
      return images.github
    case 'twitter':
      return images.twitter
    case 'stackoverflow':
      return images.stackoverflow
    case 'unsplash':
      return images.unsplash
    case 'bilibili':
      return images.bilibili
  }
}

type AccountType =
  | 'github'
  | 'stackoverflow'
  | 'twitter'
  | 'bilibili'
  | 'unsplash'
interface Account {
  type: AccountType
  url: string
}

export interface MemberModel {
  name: string
  role: string
  intro: string
  accounts: Account[]
}

const Member: FC<MemberModel> = ({ name, role, intro, accounts }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Role>{role}</Role>
      <Accounts>
        {accounts.map((a) => (
          <Account
            key={a.type}
            type={a.type}
            href={a.url}
            target="_blank"
            rel="noindex nofollow noopener"
          />
        ))}
      </Accounts>
      <Intro>{intro}</Intro>
    </Container>
  )
}

export default Member
