import React, { FC } from 'react'
import styled from 'styled-components'

import images from '../images'

const Container = styled.div`
  margin: 32px 0;

  display: flex;
`
const Logo = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 6px;
  box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.2);
`
const R = styled.div`
  margin-left: 24px;
`
const Name = styled.h3`
  margin: 4px 0;
  font-size: 1.5em;
`
const Slogan = styled.div`
  opacity: 0.8;
`
const Downloads = styled.div`
  margin-top: 16px;

  > * {
    display: inline-block;
    margin-right: 16px;
  }
`
const AppStore = styled.a`
  width: 120px;
  height: 40px;
  margin: 4px 8px 4px 0;
  background-image: url(${images.appStore});
`
const GooglePlay = styled.a`
  width: 135px;
  height: 40px;
  margin: 4px 0;
  background-image: url(${images.googlePlay});
`
const Website = styled.a`
  width: 114px;
  height: 40px;
  margin: 4px 8px 4px 0;
  background-image: url(${images.website});
`

export interface ProductModel {
  name: string
  slogan: string
  logo: any
  ios?: string
  android?: string
  web?: string
}

const Product: FC<ProductModel> = ({
  name,
  slogan,
  logo,
  ios,
  android,
  web,
}) => {
  return (
    <Container>
      <div>
        <Logo src={logo} />
      </div>
      <R>
        <Name>{name}</Name>
        <Slogan>{slogan}</Slogan>
        <Downloads>
          {web && (
            <Website
              href={web}
              target="_blank"
              rel="noindex nofollow noopener"
            />
          )}
          {ios && (
            <AppStore
              href={ios}
              target="_blank"
              rel="noindex nofollow noopener"
            />
          )}
          {android && (
            <GooglePlay
              href={android}
              target="_blank"
              rel="noindex nofollow noopener"
            />
          )}
        </Downloads>
      </R>
    </Container>
  )
}

export default Product
